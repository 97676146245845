<template>
  <div class="card-panel">
    <div class="card-content bg-white pt-20px">
      <div class="header">
        <div class="header-action">
          <div class="header-action-item">
            <span>卡系筛选</span>
            <Select v-model="cardQuery.kxlx_id" style="width: 200px" clearable>
              <Option v-for="item in cardList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>会员类型</span>
            <Select v-model="cardQuery.hylx" style="width: 200px" clearable>
              <Option value="卡系会员">卡系会员</Option>
              <Option value="普通会员">普通会员</Option>
              <Option value="全部会员">全部会员</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>省份</span>
            <Select
              clearable
              v-model="cardQuery.pid"
              placeholder="请选择"
              @on-change="changePro"
              style="width: 200px"
            >
              <Option :value="v.id" v-for="v in proList" :key="v.id">{{ v.name }}</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>城市</span>
            <Select
              clearable
              v-model="cardQuery.cid"
              placeholder="请选择"
              @on-change="changeCity"
              style="width: 200px"
            >
              <Option :value="v.id" v-for="v in cityList" :key="v.id">{{ v.name }}</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>门店</span>
            <Select clearable v-model="cardQuery.sid" placeholder="请选择" style="width: 200px">
              <Option :value="v.id" v-for="v in storeList" :key="v.id">{{ v.name }}</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>服务项目</span>
            <Select
              clearable
              multiple
              v-model="cardQuery.fwxm"
              placeholder="请选择"
              style="width: 200px"
            >
              <Option :value="v" v-for="v in itemList" :key="v">{{ v }}</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>服务员工</span>
            <Select
              clearable
              filterable
              v-model="cardQuery.fwyg"
              placeholder="请选择"
              style="width: 200px"
            >
              <Option :value="v.name" v-for="v in staffList" :key="v.id">{{ v.name }}</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>服务时间</span>
            <DatePicker
              type="daterange"
              placeholder="请选择"
              style="width: 200px"
              @on-change="handleDateChange"
            ></DatePicker>
          </div>
          <div class="header-action-item">
            <span>评价状态</span>
            <Select v-model="cardQuery.pjzt" style="width: 200px" clearable>
              <Option value="未评价">未评价</Option>
              <Option value="已评价">已评价</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>时间段</span>
            <Select v-model="cardQuery.time_type" style="width:200px" @on-change="onChangeTimeType" clearable>
              <Option value="今日">今日</Option>
              <Option value="本周">本周</Option>
              <Option value="上周">上周</Option>
              <Option value="本月">本月</Option>
              <Option value="上月">上月</Option>
              <Option value="最近30天">最近30天</Option>
            </Select>
          </div>
          <div class="header-action-item">
            <span>顾客姓名</span>
            <Input v-model="cardQuery.gkxm" placeholder="请输入姓名" style="width: 200px" />
          </div>
          <Button type="primary" @click="handleQuery">查询</Button>
          <Button type="info" @click="handleExport">导出</Button>
        </div>
      </div>
      <div class="table-panel">
        <Table :columns="dataColumns" :data="dataList"></Table>
        <div>
          <span class="records">共 {{ dataTotal }} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="dataTotal"
              :current="dataCurrentPage"
              :page-size="cardQuery.size"
              @on-change="changeDataPage"
            ></Page>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="服务评价"
      class-name="vertical-center-modal"
      width="900"
      :loading="loading"
      @on-visible-change="handleModalVisible"
      @on-ok="commentService"
    >
      <div class="search-box">
        <div class="search-row">
          <span class="sub-label">评价内容：</span>
          <div class="modal-sub-content">
            <Input v-model="modalData.pjnr" placeholder="请输入" type="textarea" />
          </div>
        </div>
        <div class="search-row">
          <span class="sub-label">评价打星：</span>
          <div class="modal-sub-content" style="height: 32px;line-height: 32px;">
            <Rate v-model="modalData.xj" :count="5"/>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import memberService from '@/services/memberService';
import cardService from '@/services/cardService';

export default {
	data() {
		return {
			cardQuery: {
				page: 1,
				size: 30,
				kxlx_id: '',
				hylx: '',
				pid: '',
				cid: '',
				sid: '',
				fwyg: '',
				start_time: '',
				end_time: '',
				pjzt: '',
				time_type: '',
				gkxm: '',
				fwxm: [],
			},
			proList: [],
			cityList: [],
			storeList: [],
			staffList: [],
			itemList: [],
			dataColumns: [
				{ type: 'index', width: 60 },
				{ title: '服务时间', key: 'rq', align: 'center' },
				{ title: '服务类型', key: 'fwlx', align: 'center' },
				{ title: '服务人', key: 'fwyg', align: 'center' },
				{ title: '服务内容', key: 'nr', align: 'center' },
				{ title: '康币', key: 'fwdj', align: 'center' },
				{ title: '用户姓名', key: 'xm', align: 'center' },
				{ title: '会员类型', key: 'hylx', align: 'center' },
				{ title: '用户手机号', key: 'sjh', align: 'center' },
				{ title: '所属门店', key: 'ssmd', align: 'center' },
				{ title: '服务评价', key: 'pjnr', align: 'center' },
				{
					title: '评价打星',
					align: 'center',
					render: (h, params) => {
						return h('Rate', {
							attrs: {
								count: 5,
								value: params.row.xj ?? 0,
								'allow-half': true,
								disabled: true,
							},
						});
					},
				},
				{
					title: '操作',
					align: 'center',
					render: (h, params) => {
						const self = this;
						if (this.kpj == 1) {
							return h(
								'Button',
								{
									attrs: {
										type: 'primary',
										size: 'small',
									},
									on: {
										click() {
											self.handleComment(params.row.id);
										},
									},
								},
								'评价',
							);
						} else {
							return h('');
						}
					},
				},
			],
			dataList: [],
			dataTotal: 0,
			dataCurrentPage: 1,
			showModal: false,
			loading: true,
			modalData: {
				pjnr: '',
				xj: 0,
				jl_id: '',
			},
			cardList: [],
			kpj: 0,
		};
	},
	created() {
		const list = JSON.parse(localStorage.getItem('userInfo'));
		memberService.getBase().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
			this.power = list.user_info.power;
			if (this.power.indexOf('服务管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('服务管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('服务管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.cardQuery.sid = this.branch_id[2];
					this.cardQuery.cid = this.branch_id[1];
					this.cardQuery.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
		});
		memberService.getServiceStaffList().then((data) => {
			this.staffList = data;
		});
		cardService.getCardType().then((data) => {
			this.cardList = data;
		});
		cardService.getServiceItems().then((data) => {
			this.itemList = data;
		});
		cardService.cardServiceKpj().then((data) => {
			this.kpj = data;
		});
		this.getCardService();
	},
	methods: {
		onChangeTimeType() {
			this.cardQuery.start_time = '';
			this.cardQuery.end_time = '';
		},
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.cardQuery.pid];
			this.cardQuery.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.cardQuery.cid];
			this.cardQuery.sid = '';
		},
		handleDateChange(val) {
			if (val.every((item) => item)) {
				this.cardQuery.start_time = val[0];
				this.cardQuery.end_time = val[1];
			} else {
				this.cardQuery.start_time = null;
				this.cardQuery.end_time = null;
			}
		},
		getCardService() {
			cardService
				.getCardServices({ ...this.cardQuery, fwxm: this.cardQuery.fwxm.join(',') })
				.then((data) => {
					this.dataTotal = data.row_size;
					this.dataCurrentPage = data.cur_page;
					this.dataList = data.list;
				});
		},
		handleQuery() {
			this.cardQuery.page = 1;
			this.getCardService();
		},
		handleExport() {
			location.href =
				'http://conlife.sskh.net:9928/api/kxgl/export?kxlx_id=' +
				this.cardQuery.kxlx_id +
				'&hylx=' +
				this.cardQuery.hylx +
				'&pid=' +
				this.cardQuery.pid +
				'&cid=' +
				this.cardQuery.cid +
				'&sid=' +
				this.cardQuery.sid +
				'&start_time=' +
				this.cardQuery.start_time +
				'&end_time=' +
				this.cardQuery.end_time +
				'&fwyg=' +
				this.cardQuery.fwyg +
				'&time_type=' +
				this.cardQuery.time_type +
				'&pjzt=' +
				this.cardQuery.pjzt +
				'&gkxm=' +
				this.cardQuery.gkxm +
				'&fwxm=' +
				this.cardQuery.fwxm.join(',');
		},
		changeDataPage(page) {
			this.cardQuery.page = page;
			this.getCardService();
		},
		handleModalVisible(visible) {
			if (!visible) {
				this.modalData = {
					pjnr: '',
					xj: 0,
				};
			}
		},
		handleComment(id) {
			this.showModal = true;
			this.modalData.jl_id = id;
		},
		commentService() {
			cardService.commentService(this.modalData).then(() => {
				const target = this.dataList.find((item) => item.id === this.modalData.jl_id);
				target.xj = this.modalData.xj;
				target.pjnr = this.modalData.pjnr;
				this.$Message.success('评价成功');
				this.showModal = false;
			});
		},
	},
};
</script>

<style lang="less" scoped>
.card-content {
  margin-bottom: 20px;
}
.bg-white {
  background-color: #fff;
}
.pt-20px {
  padding-top: 20px;
}
.table-panel {
  padding: 0 20px;
}
.search-row {
  margin: 10px 0 0 10px;
}
.sub-label {
  float: left;
  vertical-align: middle;
  line-height: 32px;
}
.modal-sub-content {
  margin-left: 70px;
}
.header-action {
  background-color: #fff;
  display: flex;
  margin-bottom: 15px;
  gap: 15px;
  flex-wrap: wrap;
  padding: 0 20px;
  span {
    margin-right: 15px;
  }
}
</style>
